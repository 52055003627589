<template>
  <div v-loading="loading" style="marginLeft: 20px">
    <el-row>
      <el-col :span="24" class="titleContent" style="marginBottom: 20px">
        <span class="title iconTitle">基础信息</span>
      </el-col>
      <el-col :span="24" class="item">
        <span>角色名称：</span>
        {{ info.name }}
      </el-col>

      <el-col :span="24" class="item">
        <span>备注：</span>
        {{ info.description }}
      </el-col>
      <el-col :span="24" class="item">
        <span>分配权限：</span>
        <el-tree default-expand-all :data="info.menuList" :props="defaultProps"></el-tree>
      </el-col>
    </el-row>
  </div>
</template>

<script type="text/babel">
import RoleApi from "@/api/RoleApi";
export default {
  name: "userDetail",
  data() {
    return {
      loading: false,
      info: {
        name: "张三",
        description: "2013-12-07",
        menuList: []
      },
      defaultProps: {
        children: 'childrenList',
        label: 'name'
      },
      data: {},
      imageView: [],
      options: [],
    };
  },
  components: {},
  methods: {
    getDetail(id) {
      this.loading = true;
      RoleApi.queryRoleById({ id: id }).then((resp) => {
        if (resp.code == "200") {
          this.info = resp.data;
          this.loading = false;
        }
      });
    }
  },
  async created() {
    this.getDetail(this.$route.params.id)
  },
};
</script>

<style lang="scss" scoped>
.item {
  box-sizing: border-box;
  margin-bottom: 10px;
  font-size: 14px;
  box-sizing: border-box;
  padding: 0 20px 0px 20px;
  padding-left: 45%;
}

.titleContent {
  font-size: 18px;
  font-weight: 400;
  color: #0286df;
  line-height: 24px;

  .iconTitle {
    margin-right: 10px;
    border-left: 3px solid #0286df;
    padding-left: 10px;
  }

  i {
    cursor: pointer;
  }
}
</style>