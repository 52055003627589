import * as AppHttpKit from '@/utils/http/AppHttpKit';
/**
 * 以下是完成API接口调用的定义规范
 */
const RoleApi = {
    search: (data) => AppHttpKit.postJSON(`/v1/role/roleQueryPage`,data),//系统-角色列表
    saveRole: (data) => AppHttpKit.postJSON(`/v1/role/saveRole`,data), // 新增角色
    queryRoleById: (data) => AppHttpKit.postJSON(`/v1/role/queryRoleById`,data), // 查询详情
    updateRole: (data) => AppHttpKit.postJSON(`/v1/role/updateRole`,data), // 更新角色信息
    deleteRole: (data) => AppHttpKit.postJSON(`/v1/role/deleteRole`,data), // 删除角色
    batchDeleteRole:(data) => AppHttpKit.postJSON(`/v1/role/batchDeleteRole`,data), // 批量删除角色
    roleAllMenulist:(data) => AppHttpKit.postJSON(`/v1/menu/menulist`,data), // 获取角色菜单列表（角色类型不同，展示不同的菜单列表）
    findRoleTypeSelect: (data) => AppHttpKit.get(`/v1/role/findRoleTypeSelect`,data), // 获取角色类型下拉框
    queryAllPermissions:(data) => AppHttpKit.postJSON(`/v1/queryAllPermissions/`,data),
    queryPermissionsofMine:(data) => AppHttpKit.postJSON(`/v1/queryPermissionsofMine/`,data),
    savePermissions:(data) => AppHttpKit.postJSON(`/v1/savePermissionsOf/`,data),
}
export default RoleApi;